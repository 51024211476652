@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300');

/* Base Styles
********************************************************************* */
html {
  font-size: 62.5%;
}
body {
  font-size: 1.5em;
  line-height: 1.6;
  font-weight: 400;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #222;
}
/* Grid
********************************************************************* */
.container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
.column,
.columns {
  width: 100%;
  float: left;
  box-sizing: border-box;
}

/* For devices larger than 400px */
@media (min-width: 400px) {
  .container {
    width: 85%;
    padding: 0;
  }
}

/* For devices larger than 550px */
@media (min-width: 550px) {
  .container {
    width: 80%;
  }
  .column,
  .columns {
    margin-left: 4%;
  }
  .column:first-child,
  .columns:first-child {
    margin-left: 0;
  }
  .one.column,
  .one.columns {
    width: 4.66666666667%;
  }
  .two.columns {
    width: 13.3333333333%;
  }
  .three.columns {
    width: 22%;
  }
  .four.columns {
    width: 30.6666666667%;
  }
  .five.columns {
    width: 39.3333333333%;
  }
  .six.columns {
    width: 48%;
  }
  .seven.columns {
    width: 56.6666666667%;
  }
  .eight.columns {
    width: 65.3333333333%;
  }
  .nine.columns {
    width: 74%;
  }
  .ten.columns {
    width: 82.6666666667%;
  }
  .eleven.columns {
    width: 91.3333333333%;
  }
  .twelve.columns {
    width: 100%;
    margin-left: 0;
  }
  .one-third.column {
    width: 30.6666666667%;
  }
  .two-thirds.column {
    width: 65.3333333333%;
  }
  .one-half.column {
    width: 48%;
  }
  /* Offsets */
  .offset-by-one.column,
  .offset-by-one.columns {
    margin-left: 8.66666666667%;
  }
  .offset-by-two.column,
  .offset-by-two.columns {
    margin-left: 17.3333333333%;
  }
  .offset-by-three.column,
  .offset-by-three.columns {
    margin-left: 26%;
  }
  .offset-by-four.column,
  .offset-by-four.columns {
    margin-left: 34.6666666667%;
  }
  .offset-by-five.column,
  .offset-by-five.columns {
    margin-left: 43.3333333333%;
  }
  .offset-by-six.column,
  .offset-by-six.columns {
    margin-left: 52%;
  }
  .offset-by-seven.column,
  .offset-by-seven.columns {
    margin-left: 60.6666666667%;
  }
  .offset-by-eight.column,
  .offset-by-eight.columns {
    margin-left: 69.3333333333%;
  }
  .offset-by-nine.column,
  .offset-by-nine.columns {
    margin-left: 78%;
  }
  .offset-by-ten.column,
  .offset-by-ten.columns {
    margin-left: 86.6666666667%;
  }
  .offset-by-eleven.column,
  .offset-by-eleven.columns {
    margin-left: 95.3333333333%;
  }
  .offset-by-one-third.column,
  .offset-by-one-third.columns {
    margin-left: 34.6666666667%;
  }
  .offset-by-two-thirds.column,
  .offset-by-two-thirds.columns {
    margin-left: 69.3333333333%;
  }
  .offset-by-one-half.column,
  .offset-by-one-half.columns {
    margin-left: 52%;
  }
}
/* Typography
********************************************************************* */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}
h1 {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}
h3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -0.1rem;
}
h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -0.08rem;
}
h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05rem;
}
h6 {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0;
}

/* Larger than phablet */
@media (min-width: 550px) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 3.6rem;
  }
  h4 {
    font-size: 3rem;
  }
  h5 {
    font-size: 2rem;
  }
  h6 {
    font-size: 1.5rem;
  }
}
p {
  margin-top: 0;
}
/* Links
********************************************************************* */
a {
  color: #1eaedb;
}
a:hover {
  color: #0fa0ce;
}
/* Header Section
********************************************************************* */

header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #e55d87;
  background: -moz-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: -webkit-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: linear-gradient(135deg, #6b1a12 0%, #5fc3e4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e55d87', endColorstr='#5fc3e4', GradientType=1 );
}
.title {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 2rem;
  max-width: 960px;
  text-align: center;
}
.title .smallsep {
  background: #fff;
  height: 2px;
  width: 70px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.title h1 {
  font-size: 80px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 0.85;
  margin-bottom: 28px;
  margin: 0;
  padding: 0;
  color: #ffffff;
}
.title h2 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-top: 50px;
}

@media only screen and (max-height: 700px) {
  .title h1 {
    font-size: 80px;
  }
}
.title p {
  max-width: 600px;
  margin: 0 auto;
  line-height: 150%;
}

@media only screen and (max-width: 500px) {
  .title h1 {
    font-size: 65px;
  }
}
.title .icon {
  color: #ffffff;
  font-size: 50px;
}
.main_nav {
  position: fixed;
  top: 0px;
  max-height: 50px;
  z-index: 999;
  width: 100%;
  padding-top: 17px;
  background: none;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  top: -100px;
  padding-bottom: 6px;
}

@media only screen and (max-width: 766px) {
  .main_nav {
    padding-top: 0px;
  }
}
.open-nav {
  max-height: 400px !important;
}
.sticky {
  background-color: #ffffff;
  opacity: 1;
  top: 0px;
}
nav {
  width: 100%;
  margin-top: 5px;
}

@media only screen and (max-width: 766px) {
  nav {
    width: 100%;
  }
}
nav ul {
  list-style: none;
  overflow: hidden;
  text-align: center;
}

@media only screen and (max-width: 766px) {
  nav ul {
    padding-top: 0px;
    margin-bottom: 22px;
    text-align: center;
    width: 100%;
  }
}
nav ul li {
  display: inline-block;
  margin-left: 35px;
  line-height: 1.5;
  letter-spacing: 1px;
}

@media only screen and (max-width: 766px) {
  nav ul li {
    width: 100%;
    padding: 7px 0;
    margin: 0;
  }
  nav ul li:first-child {
    margin-top: 70px;
  }
}
nav ul a {
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
}
nav ul a:hover {
  color: #cfcfcf;
}

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 5vh;
  display: block;
  text-align: center;
  font-size: 20px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  height: 13px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  z-index: 9;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 2s ease-in-out infinite;
  -moz-animation: fade_move_down 2s ease-in-out infinite;
  animation: fade_move_down 2s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
/* About Section
********************************************************************* */

#about {
  padding: 100px 0 50px 0;
}
/* Team Section
********************************************************************* */
#experience {
  padding: 20px 0 100px 0;
}

.company-wrapper {
  display: flex;
}

.company-title {
  width: 40%;
}

.company-date {
  align-self: center;
  text-align: right;
  width: 60%;
}

.pad-top {
  padding-top: 40px;
}

#education {
  padding: 0px 0 100px 0;
}

/* Skills Section
********************************************************************* */
#skills {
  padding: 100px 0 100px 0;
  background-color: #f5f5f5;
}

.text-below-image {
  text-align: center;
  width: 80px;
}

.progressBar {
  margin-bottom: 26px;
  margin-bottom: 1.66em;
}
.progressBar h4 {
  font-size: 16px;
  text-transform: none;
  margin-bottom: 7px;
  margin-bottom: 0.33em;
}
.progressBarContainer {
  width: 100%;
  height: 8px;
  background: #e1e1e1;
  overflow: hidden;
}
.progressBarValue {
  height: 8px;
  float: left;
  background: #e55d87; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: -webkit-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: linear-gradient(135deg, #e55d87 0%, #5fc3e4 100%);
}
.value-00 {
  width: 0;
}
.value-10 {
  width: 10%;
}
.value-20 {
  width: 20%;
}
.value-30 {
  width: 30%;
}
.value-40 {
  width: 40%;
}
.value-50 {
  width: 50%;
}
.value-60 {
  width: 60%;
}
.value-70 {
  width: 70%;
}
.value-80 {
  width: 80%;
}
.value-90 {
  width: 90%;
}
.value-100 {
  width: 100%;
}
/* Portfolio Section
********************************************************************* */
#portfolio {
  padding: 100px 0 100px 0;
}
.image {
  background-color: #5a5a5a;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s;
}
.image:hover {
  opacity: 0.6;
  transition: 0.3s;
  background-repeat: no-repeat;
  background-position: center;
}

/* Testimonial Section
********************************************************************* */
#testimonial {
  background-color: #f5f5f5;
  padding: 100px 0 100px 0;
}
.quoteLoop {
  height: auto;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.quote {
  margin: 10px 30px;
  height: inherit;
  top: 0px;
  display: none;
  text-align: center;
}
/* Contact Section
********************************************************************* */
#contact {
  padding: 100px 0 100px 0;
}
input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea,
select {
  height: 38px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #f5f5f5;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;
}
textarea {
  min-height: 250px;
}

.btn-submit {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background: #5fc3e4;
  border-radius: 0px;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.btn-submit:hover {
  background: #e45f71;
  text-decoration: none;
}
/* Footer Section
********************************************************************* */
footer {
  min-height: 120px;
  padding: 40px 0 40px 0;
  background: #e55d87;
  background: -moz-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: -webkit-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: linear-gradient(135deg, #6b1a12 0%, #5fc3e4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e55d87', endColorstr='#5fc3e4', GradientType=1 );
  box-sizing: border-box;
}
footer p {
  color: #ffffff;
  margin: 20px 0 0 0;
}
.socialIcons {
  font-size: 34px;
  color: rgba(255, 255, 255, 0.7);
}
/* Lists
********************************************************************* */
ul {
  list-style: circle inside;
}
ol {
  list-style: decimal inside;
}
ol,
ul {
  padding-left: 0;
  margin-top: 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%;
}
li {
  margin-bottom: 1rem;
}
/* Spacing
********************************************************************* */
button,
.button {
  margin-bottom: 1rem;
}
input,
textarea,
select,
fieldset {
  margin-bottom: 1.5rem;
}
pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
  margin-bottom: 2.5rem;
}
/* Utilities
********************************************************************* */
.u-full-width {
  width: 100%;
  box-sizing: border-box;
}
.u-max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}
.u-pull-right {
  float: right;
}
.u-pull-left {
  float: left;
}
/* Clearing
********************************************************************* */
.container:after,
.row:after,
.u-cf {
  content: '';
  display: table;
  clear: both;
}
/* Misc
********************************************************************* */

.icon {
  padding-right: 10px;
  color: #e55d87;
}
.block {
  width: 70px;
  height: 2px;
  background: #e55d87; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: -webkit-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
  background: linear-gradient(135deg, #e55d87 0%, #5fc3e4 100%);
  margin-bottom: 50px;
}
